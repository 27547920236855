import React, { useEffect, useState } from 'react';
import { Box } from '@mui/system';
import { Typography } from '@mui/material';

import TextNumberContainer from 'components/commons/TextNumberContainer';
import { LinearProgressStyled } from 'pages/Home/HomeStyles';

interface IAvance {
  percentage?: number | 0;
}

const Avance: React.FC<IAvance> = (props: IAvance) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    setPercentage(props.percentage ?? 0);
    return () => {
      setPercentage(0);
    };
  }, [props.percentage]);

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgressStyled
            variant="determinate"
            value={percentage}
            defaultValue={0}
          />
        </Box>
        <Box sx={{ minWidth: 40 }}>
          <Typography variant="body2" color="text.secondary">
            <TextNumberContainer value={percentage} />%
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Avance;
