import axios from 'utils/axios';
import { IContract, IContractTaskFilter } from 'redux/slices/contract';

export async function loadContracts(
  contractTaskFilter: IContractTaskFilter
): Promise<IContract[]> {
  const retorno: IContract[] = await axios.get(
    `/contract/${contractTaskFilter.projectId}/${contractTaskFilter.taskId}`
  );
  return retorno;
}
