import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// import type { RootState } from 'redux/store';

export interface IIcon {
  id: number;
  name: string;
  bytes: string;
}

export interface IconState {
  icons: IIcon[];
}

const initialState: IconState = {
  icons: []
};

const iconSlice = createSlice({
  name: 'icon',
  initialState,
  reducers: {
    iconRequest: (state) => {
      return state;
    },
    iconResponse: (state, action: PayloadAction<IIcon[]>) => {
      state.icons = action.payload;
      return state;
    }
  }
});

// Actions
export const actions = iconSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const teste = (state: RootState): IGeometry[] => state.geometry.geometries;

// Reducer
export default iconSlice.reducer;
