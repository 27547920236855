import { takeEvery, ForkEffect, put } from 'redux-saga/effects';

import { actions as contractActions, IContract } from 'redux/slices/contract';
// import { actions as contractPhotoActions } from 'redux/slices/contractphoto';
import { PayloadAction } from '@reduxjs/toolkit';
import * as contractService from 'services/contractService';
import apiSaga from 'redux/sagas/apiSaga';

function* _contractTaskRequest(payloadAction: PayloadAction<any>): any {
  yield* apiSaga(
    contractService.loadContracts,
    payloadAction.payload,
    function* (data: IContract[]) {
      // yield put(contractPhotoActions.contractPhotoClear());
      yield put(contractActions.contractTaskResponse(data));
    },
    function* (error: any) {
      console.log(error);
      // yield put(contractPhotoActions.contractPhotoClear());
      yield put(contractActions.contractTaskResponse([]));
    }
  );
}

export function* watchContractSagas(): Generator<ForkEffect, void> {
  yield takeEvery(contractActions.contractTaskRequest, _contractTaskRequest);
}

const ContractSaga = watchContractSagas;

export default ContractSaga;
