/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'utils/axios';
import {
  IDocumentContract,
  IDocumentContractFilter,
  IFileFilter
} from 'redux/slices/documentcontract';

export async function loadDocumentsContract(
  documentContractFilter: IDocumentContractFilter
): Promise<IDocumentContract[]> {
  const retorno: IDocumentContract[] = await axios.get(
    `/documentcontract/${documentContractFilter.projectId}/${documentContractFilter.contractId}`
  );
  return retorno;
}

export async function getDocumentContract(
  fileFilter: IFileFilter
): Promise<IDocumentContract[]> {
  const headers: any = {
    headers: {
      Accept: '*/*',
      'Cache-Control': 'no-cache'
    },
    responseType: 'arraybuffer'
  };

  const retorno: IDocumentContract[] = await axios.get(
    `/documentcontract/${fileFilter.id}`,
    headers
  );
  return retorno;
}
