import React from 'react';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import store, { persistor } from 'redux/store';
import Home from 'pages/Home';
import MuiThemeProvider from 'theme';

const App: React.FC = () => {
  const publicUrl = '/mapa';
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MuiThemeProvider>
          <BrowserRouter basename={publicUrl}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/:slugCountry" element={<Home />} />
              <Route path="/:slugCountry/:slugProject" element={<Home />} />
              <Route
                path="/:slugCountry/:slugProject/:taskId"
                element={<Home />}
              />
              <Route path="*" element={<Navigate to="/" replace={true} />} />
            </Routes>
          </BrowserRouter>
        </MuiThemeProvider>
      </PersistGate>
    </Provider>
  );
};

export default App;
