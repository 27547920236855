/* eslint-disable @typescript-eslint/no-unused-vars */
// https://tomchentw.github.io/react-google-maps/#polygon

import React, { useState, useCallback, useRef, useEffect } from 'react';
import { GoogleMap, Libraries, useJsApiLoader } from '@react-google-maps/api';
import {
  OverlappingMarkerSpiderfier,
  SpiderOptions
} from 'ts-overlapping-marker-spiderfier';
import { useCustomDispatch } from 'hooks/redux';
import { isEmpty, isUndefined } from 'lodash';

import { actions as taskActions } from 'redux/slices/task';
import { IGeometry } from 'redux/slices/geometry';
import { IIcon } from 'redux/slices/icon';
import { IOption } from 'components/searchbox/searchbox';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';

const libraries: Libraries = ['drawing', 'places'];

const polygonOptions = {
  editable: false,
  draggable: false,
  fillOpacity: 0.4,
  strokeColor: '#babcbd',
  strokeOpacity: 0.8,
  strokeWeight: 1
};

const polylineOptions = {
  editable: false,
  strokeOpacity: 0.75,
  strokeWeight: 6
};

interface IMapa {
  geometries: IGeometry[];
  geometry?: IOption;
  icons: IIcon[];
  slugProject: string | undefined;
}

const Mapa: React.FC<IMapa> = (props: IMapa) => {
  const [map, setMap] = React.useState<google.maps.Map | null>();
  const [activeMarker, setActiveMarker] = useState<string>();
  const [markers, setMarkers] = useState<google.maps.Marker[]>([]);
  const [polygons, setPolygons] = useState<google.maps.Polygon[]>([]);
  const [polylines, setPolylines] = useState<google.maps.Polyline[]>([]);
  const [oms, setOms] = useState<OverlappingMarkerSpiderfier>();

  const dispatch = useCustomDispatch();

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  let infoWindow: google.maps.InfoWindow;

  const { isLoaded /* , loadError */ } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyD4V4hOu54KOcWtmfpcXPzmTIAYiuVg600',
    libraries
  });

  const onMapLoad = useCallback((mapInstance: google.maps.Map) => {
    const omsSpider: OverlappingMarkerSpiderfier =
      new OverlappingMarkerSpiderfier(mapInstance, {
        markersWontMove: true,
        markersWontHide: false,
        legWeight: 3,
        keepSpiderfied: true,
        nearbyDistance: 30
      });
    setOms(omsSpider);
    setMap(mapInstance);
  }, []);

  const onUnmount = useCallback(function callback(map: google.maps.Map) {
    setMap(null);
  }, []);

  useEffect(
    () => {
      if (!isEmpty(map)) {
        clearMap();

        const bounds = new google.maps.LatLngBounds();

        let geos: IGeometry[] = props.geometries;
        if (!isEmpty(props.geometry)) {
          geos = props.geometries.filter(
            (g) =>
              g.taskId === props.geometry?.taskId &&
              g.projectId === props.geometry.projectId
          );
          onLoadTask({
            id: props.geometry.id,
            countryId: 0,
            slugCountry: '',
            projectId: props.geometry.projectId,
            slugProject: '',
            taskId: props.geometry.taskId,
            task: '',
            natureTaskId: 0,
            natureTask: '',
            iconId: 0,
            lat: 0,
            lng: 0,
            auxiliaryId: '',
            geometries: ''
          });
        }

        if (!isEmpty(geos)) {
          geos.forEach((geometry) => {
            if (
              !isUndefined(geometry) &&
              !isUndefined(geometry.geometries) &&
              !isUndefined(oms)
            ) {
              addMarker(bounds, geometry, oms);
              addPolygon(bounds, geometry);
              addPolyline(bounds, geometry);
            }
          });

          map.fitBounds(bounds);
        } else {
          map.setCenter({ lat: -15.79117796, lng: -47.89705163 });
          map.setZoom(4);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [map, props.geometries, props.geometry]
  );

  function clearMap(): void {
    if (map != null) {
      if (!isEmpty(markers)) {
        markers?.forEach((m: google.maps.Marker) => m.setMap(null));
        setMarkers([]);
      }
      if (!isEmpty(polygons)) {
        polygons?.forEach((poly: google.maps.Polygon) => poly.setMap(null));
        setPolygons([]);
      }
      if (!isEmpty(polylines)) {
        polylines?.forEach((pol: google.maps.Polyline) => {
          pol.setMap(null);
          pol.setOptions({ map: null });
        });
        setPolylines([]);
      }
    }
  }

  function addMarker(
    bounds: google.maps.LatLngBounds,
    geometry: IGeometry,
    oms: OverlappingMarkerSpiderfier
  ): void {
    const geometries = JSON.parse(geometry.geometries);
    if (
      !isUndefined(geometries) &&
      !isUndefined(geometries.geometry) &&
      !isUndefined(geometries.geometry.markers)
    ) {
      const markerArray: google.maps.Marker[] = markers;
      geometries.geometry.markers.forEach((geo: any) => {
        const latlng = {
          lat: geo.lat,
          lng: geo.lng
        };
        bounds.extend(latlng);
        const marker = new google.maps.Marker({
          position: latlng,
          icon: getIcon(geometry.iconId),
          title: geometry.task,
          map
        });
        // marker.addListener('click', () => onLoadTask(geometry));
        markerArray.push(marker);
        oms.addMarker(marker, () => onLoadTask(geometry));
      });
      setMarkers(markerArray);
    }
  }

  function addPolygon(
    bounds: google.maps.LatLngBounds,
    geometry: IGeometry
  ): void {
    const geometries = JSON.parse(geometry.geometries);
    if (
      !isUndefined(geometries) &&
      !isUndefined(geometries.geometry) &&
      !isUndefined(geometries.geometry.polygons)
    ) {
      const polygonArray: google.maps.Polygon[] = polygons;
      geometries.geometry.polygons.forEach((geo: any) => {
        const polygon = new google.maps.Polygon({
          ...polygonOptions,
          paths: geo,
          fillColor: geometries.geometry.color,
          strokeColor: geometries.geometry.color,
          map
        });
        polygon.addListener('click', () => onLoadTask(geometry));
        polygonArray.push(polygon);
        // attachInfoWindow(polygon, geometry.task);
        geo.forEach((latlng: any) => bounds.extend(latlng));
      });
      setPolygons(polygonArray);
    }
  }

  function addPolyline(
    bounds: google.maps.LatLngBounds,
    geometry: IGeometry
  ): void {
    const geometries = JSON.parse(geometry.geometries);
    if (
      !isUndefined(geometries) &&
      !isUndefined(geometries.geometry) &&
      !isUndefined(geometries.geometry.polylines)
    ) {
      const polylineArray: google.maps.Polyline[] = polylines;
      geometries.geometry.polylines.forEach((geo: any) => {
        const polyline = new google.maps.Polyline({
          ...polylineOptions,
          path: geo,
          strokeColor: geometries.geometry.color,
          map
        });

        polyline.addListener('click', () => onLoadTask(geometry));
        // attachInfoWindow(polyline, geometry.task);
        geo.forEach((latlng: any) => bounds.extend(latlng));
        polylineArray.push(polyline);
      });
      setPolylines(polylineArray);
    }
  }

  function attachInfoWindow(shape: any, title: string): void {
    google.maps.event.addListener(shape, 'mouseover', function (e: any) {
      infoWindow = new google.maps.InfoWindow();
      infoWindow.setContent(title);
      infoWindow.setPosition(e.latLng);
      infoWindow.addListener('click', () => alert('xxxx'));
      infoWindow.open(map);
    });
    google.maps.event.addListener(shape, 'mouseout', function () {
      infoWindow.close();
    });
  }

  function getIcon(iconId: number): google.maps.Icon | null {
    if (!isUndefined(iconId) && !isEmpty(props.icons)) {
      const icon = props.icons.filter((i: IIcon) => i.id === iconId);
      return {
        url: `data:image/png;base64,${icon[0].bytes}`,
        scaledSize: new google.maps.Size(26, 37),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(13, 37)
      };
    }
    return null;
  }

  function onLoadTask(geometry: IGeometry): void {
    handleActiveMarker(geometry.id);

    dispatch(
      taskActions.taskRequest({
        projectId: geometry.projectId,
        id: geometry.taskId
      })
    );
  }

  const handleActiveMarker = (marker: any): void => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const getHeightMap = (): string => {
    if (isXs && !isEmpty(props.slugProject)) {
      return `calc(100vh - 128px)`;
    }
    return `calc(100vh - 64px)`;
  };

  return (
    <>
      {isLoaded && (
        <React.Fragment>
          <GoogleMap
            mapContainerStyle={{
              width: '100%',
              height: getHeightMap()
            }}
            options={{
              disableDefaultUI: true,
              zoomControl: true,
              mapTypeControl: true,
              styles: [
                {
                  featureType: 'transit',
                  elementType: 'labels.icon',
                  stylers: [{ visibility: 'off' }]
                },
                {
                  featureType: 'poi',
                  stylers: [{ visibility: 'off' }]
                }
              ]
            }}
            onLoad={onMapLoad}
            onUnmount={onUnmount}
            zoom={15}
          ></GoogleMap>
        </React.Fragment>
      )}
    </>
  );
};

export default Mapa;
