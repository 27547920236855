import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface MapState {
  showPanel: boolean;
}

const initialState: MapState = {
  showPanel: false
};

const mapSlice = createSlice({
  name: 'map',
  initialState,
  reducers: {
    showPanel: (state) => {
      state.showPanel = true;
      return state;
    },
    hidePanel: (state, action: PayloadAction) => {
      state.showPanel = false;
      return state;
    }
  }
});

// Actions
export const actions = mapSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const teste = (state: RootState): IGeometry[] => state.geometry.geometries;

// Reducer
export default mapSlice.reducer;
