/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'utils/axios';
import {
  IDocumentTask,
  IDocumentTaskFilter,
  IFileFilter
} from 'redux/slices/documenttask';

export async function loadDocumentsTask(
  documentTaskFilter: IDocumentTaskFilter
): Promise<IDocumentTask[]> {
  const retorno: IDocumentTask[] = await axios.get(
    `/documenttask/${documentTaskFilter.projectId}/${documentTaskFilter.taskId}`
  );
  return retorno;
}

export async function getDocumentTask(
  fileFilter: IFileFilter
): Promise<IDocumentTask[]> {
  const headers: any = {
    headers: {
      Accept: '*/*',
      'Cache-Control': 'no-cache'
    },
    responseType: 'arraybuffer'
  };

  const retorno: IDocumentTask[] = await axios.get(
    `/documenttask/${fileFilter.id}`,
    headers
  );
  return retorno;
}
