import { isNaN } from 'lodash';
import React from 'react';

interface ITextNumberContainer {
  value: number | 0;
}

const TextNumberContainer: React.FC<ITextNumberContainer> = (
  props: ITextNumberContainer
) => {
  return (
    <div style={{ display: 'inline' }}>
      {Number(isNaN(props.value) ? 0 : props.value).toLocaleString(
        navigator.language,
        {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }
      )}
    </div>
  );
};

export default TextNumberContainer;
