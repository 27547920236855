/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'utils/axios';
import {
  IContractPhoto,
  IContractPhotoFilter
} from 'redux/slices/contractphoto';

export async function loadContractPhotos(
  contractPhotoFilter: IContractPhotoFilter
): Promise<IContractPhoto[]> {
  const retorno: IContractPhoto[] = await axios.get(
    `/contractphoto/${contractPhotoFilter.projectId}/${contractPhotoFilter.contractId}`
  );
  return retorno;
}
