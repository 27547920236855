import React from 'react';
import {
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { useCustomSelector } from 'hooks/redux';

import { Translator } from 'components/I18n';
import Avance from 'components/project/Avance';
import TextDateContainer from 'components/commons/TextDateContainer';
import { CardStyled } from 'pages/Home/HomeStyles';

const Project: React.FC = () => {
  const { project } = useCustomSelector((state) => state.project);
  return (
    <>
      <CardStyled raised>
        <CardContent>
          <CardHeader
            title=<Translator path="labels.project.title" />
            style={{ textAlign: 'center' }}
          />
          <List component="nav" dense disablePadding>
            <ListItem dense disablePadding>
              <ListItemText
                primary={project.name}
                className="primary"
                primaryTypographyProps={{
                  fontWeight: '700'
                }}
              />
            </ListItem>
            <Divider />
            <ListItem dense disablePadding>
              <ListItemText
                primary=<Translator path="labels.project.financing_standard" />
                secondary={project.financingStandard}
              />
            </ListItem>
            <Divider />
            <ListItem dense disablePadding>
              <ListItemText
                primary=<Translator path="labels.project.borrower" />
                secondary={project.borrower}
              />
            </ListItem>
            <Divider />
            <ListItem dense disablePadding>
              <ListItemText
                primary=<Translator path="labels.project.executor" />
                secondary={project.executor}
              />
            </ListItem>
            <Divider />
            <ListItem dense disablePadding>
              <ListItemText
                primary=<Translator path="labels.project.signature" />
                secondary=<TextDateContainer date={project.signature} />
              />
              <ListItemText
                primary=<Translator path="labels.project.current" />
                secondary=<TextDateContainer date={project.current} />
              />
            </ListItem>
            <Divider />
            <ListItem dense disablePadding>
              <ListItemText
                primary=<Translator path="labels.project.percentage" />
                secondary=<Avance percentage={project.percentage} />
              />
            </ListItem>
          </List>
        </CardContent>
      </CardStyled>
    </>
  );
};

export default Project;
