/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDocumentTask {
  id?: number;
  projectId?: number;
  taskId?: number;
  name?: string;
  size?: number;
  extention?: string;
  mimetype?: string;
}

export interface IDocumentTaskFilter {
  projectId?: number;
  taskId?: number;
}

export interface IFileFilter {
  id?: number;
  name?: string;
}

export interface IDocumentTaskState {
  documents: IDocumentTask[];
  documentTaskFilter: IDocumentTaskFilter;
  file: IFileFilter;
}

// export const UNINITIALIZED = 'UNINITIALIZED';
// type State = ITask | typeof UNINITIALIZED | null;

const initialState: IDocumentTaskState = {
  documents: [],
  documentTaskFilter: {} as IDocumentTaskFilter,
  file: {} as IFileFilter
};

const documentTaskSlice = createSlice({
  name: 'documentsTask',
  initialState,
  reducers: {
    documentTaskRequest: (
      state,
      action: PayloadAction<IDocumentTaskFilter>
    ) => {
      state.documentTaskFilter = action.payload;
      return state;
    },
    documentTaskResponse: (state, action: PayloadAction<IDocumentTask[]>) => {
      state.documents = action.payload;
      return state;
    },
    documentTaskClear: (state) => {
      state = initialState;
      return state;
    },
    fileRequest: (state, action: PayloadAction<IFileFilter>) => {
      state.file = action.payload;
    },
    fileResponse: (state) => {
      console.log('nada a fazer');
    }
  }
});

// Actions
export const actions = documentTaskSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const teste = (state: RootState): IGeometry[] => state.geometry.geometries;

// Reducer
export default documentTaskSlice.reducer;
