/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useCustomSelector, useCustomDispatch } from 'hooks/redux';
import FileDownload from '@mui/icons-material/FileDownload';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import {
  IDocumentTask,
  actions as actionsDocumentTask
} from 'redux/slices/documenttask';

import 'photoswipe/dist/photoswipe.css';
import {
  Avatar,
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import { Translator } from 'components/I18n';
import { isEmpty, toNumber } from 'lodash';
import TextNumberContainer from 'components/commons/TextNumberContainer';

interface IDocumentTaskProps {
  projectId?: number;
  taskId?: number;
}

const DocumentTask: React.FC<IDocumentTaskProps> = (
  props: IDocumentTaskProps
) => {
  const { projectId, taskId } = props;
  const { documents } = useCustomSelector((state) => state.documenttask);

  const filterDocuments: IDocumentTask[] = documents.filter(
    (fa) => fa.projectId === projectId && fa.taskId === taskId
  );

  const dispatch = useCustomDispatch();

  useEffect(() => {
    dispatch(
      actionsDocumentTask.documentTaskRequest({
        projectId,
        taskId
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, taskId]);

  function handleClick(d: IDocumentTask): any {
    dispatch(
      actionsDocumentTask.fileRequest({
        id: d.id,
        name: d.name
      })
    );
  }

  return (
    <>
      {!isEmpty(filterDocuments) && (
        <>
          <Box sx={{ height: '5px' }} />
          <Typography variant="h6">
            <Translator path="labels.contract.documents" />
          </Typography>
          <Divider />
          <List dense={true}>
            {filterDocuments.map((d) => (
              <ListItem
                key={d.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="dowload"
                    onClick={() => handleClick(d)}
                  >
                    <FileDownload />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <AttachFileIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={d.name}
                  secondary={
                    <div>
                      <TextNumberContainer value={toNumber(d.size) / 1024} />{' '}
                      bytes
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default DocumentTask;
