import React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useCustomSelector } from 'hooks/redux';

import { IGeometry, geometries } from 'redux/slices/geometry';
import { FormControl } from '@mui/material';

export interface IOption {
  label: string;
  id: string;
  projectId: number;
  taskId: number;
}

interface ISearchbox {
  onChangeGeometry: (value: object) => void;
  width: number;
}

const Searchbox: React.FC<ISearchbox> = (props: ISearchbox) => {
  const geo: IGeometry[] = useCustomSelector(geometries);
  const geos: IOption[] = geo.map((g) => {
    return {
      label: `${g.auxiliaryId} - ${g.task}`,
      id: g.id,
      taskId: g.taskId,
      projectId: g.projectId
    };
  });
  return (
    <>
      <FormControl>
        <Autocomplete
          disablePortal
          isOptionEqualToValue={(option, value) =>
            option.taskId === value.taskId &&
            option.projectId === value.projectId
          }
          id="combo-box-demo"
          options={geos}
          size="small"
          sx={{
            width: props.width,
            backgroundColor: '#FFF',
            borderRadius: '4px'
          }}
          renderInput={(params) => (
            <TextField {...params} placeholder="Pesquise pelo entregável" />
          )}
          onChange={(event: any, value: any) => props.onChangeGeometry(value)}
        />
      </FormControl>
    </>
  );
};

export default Searchbox;
