import React from 'react';
import { isEmpty, isNull, isUndefined } from 'lodash';
import { format } from 'date-fns';

interface ITextDateContainer {
  date: string | null | undefined;
}

const TextDateContainer: React.FC<ITextDateContainer> = (
  props: ITextDateContainer
) => {
  const current: any =
    !isEmpty(props) &&
    !isEmpty(props.date) &&
    !isNull(props.date) &&
    !isUndefined(props.date)
      ? format(new Date(props.date), 'dd/MM/yyyy')
      : '';

  return (
    <>
      <div style={{ display: 'inline' }}>{current}</div>
    </>
  );
};

export default TextDateContainer;
