import { actions as authActions } from 'redux/slices/auth';
import { takeEvery, ForkEffect, put } from 'redux-saga/effects';
import { getAccessToken } from 'services/authService';
import apiSaga from 'redux/sagas/apiSaga';

function* _login(): any {
  yield* apiSaga(
    getAccessToken,
    undefined,
    function* (data: any) {
      yield put(authActions.saveAccessToken(data));
    },
    function* (error: any) {
      console.log(error);
      yield put(authActions.removeAccessToken());
    }
  );
}

export function* watchAuthSagas(): Generator<ForkEffect, void> {
  yield takeEvery(authActions.login, _login);
}

const authSaga = watchAuthSagas;

export default authSaga;
