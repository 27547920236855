/* eslint-disable @typescript-eslint/restrict-template-expressions */
import axios from 'axios';
import store from 'redux/store';
import { isUndefined } from 'lodash';

const axiosInstance = axios.create();

/*
console.log('REACT_APP_URL_API: ', process.env.REACT_APP_URL_API);
console.log(
  'REACT_APP_CLIENT_AUTHORIZATION: ',
  process.env.REACT_APP_CLIENT_AUTHORIZATION
);
console.log(
  'REACT_APP_GOOGLE_MAP_API_KEY: ',
  process.env.REACT_APP_GOOGLE_MAP_API_KEY
);
*/

axiosInstance.defaults.baseURL = process.env.REACT_APP_URL_API;

axiosInstance.interceptors.request.use(
  (config) => {
    const configReturn = { ...config };
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    configReturn.headers = config.headers || {};
    configReturn.headers['Access-Control-Allow-Origin'] = '*';
    if (!isUndefined(config.url) && !config.url.endsWith('token')) {
      configReturn.headers.Authorization = `Bearer ${
        store.getState().auth.accessToken.access_token
      }`;
    }
    return configReturn;
  },
  async (error) => await Promise.reject(error)
);

export default axiosInstance;
