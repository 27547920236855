import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export interface IGeometry {
  id: string;
  countryId: number;
  slugCountry: string;
  projectId: number;
  slugProject: string;
  taskId: number;
  task: string;
  natureTaskId: number;
  natureTask: string;
  iconId: number;
  lat: number;
  lng: number;
  auxiliaryId: string;
  geometries: string;
}

export interface IGeometryFilter {
  slugCountry: string | null;
  slugProject: string | null;
  taskId: string | null;
}
export interface ITask {
  projectId?: number;
  taskId?: number;
}

export interface GeometryState {
  geometryFilter: IGeometryFilter;
  geometries: IGeometry[];
}

const initialState: GeometryState = {
  geometryFilter: {
    slugCountry: null,
    slugProject: null,
    taskId: null
  },
  geometries: []
};

const geometrySlice = createSlice({
  name: 'geometry',
  initialState,
  reducers: {
    geometriesRequest: (state, action: PayloadAction<IGeometryFilter>) => {
      state.geometryFilter = action.payload;
      return state;
    },
    geometriesResponse: (state, action: PayloadAction<IGeometry[]>) => {
      state.geometries = action.payload;
      return state;
    }
  }
});

// Actions
export const actions = geometrySlice.actions;

// Selectors
export const geometries = (state: RootState): IGeometry[] =>
  state.geometry.geometries;

// Reducer
export default geometrySlice.reducer;
