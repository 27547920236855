import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action
} from '@reduxjs/toolkit';

import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from 'redux-persist';

import createSagaMiddleware from 'redux-saga';
import storage from 'redux-persist/lib/storage';

import authReducer from 'redux/slices/auth';
import settingsReducer from 'redux/slices/settings';
import geometryReducer from 'redux/slices/geometry';
import iconReducer from 'redux/slices/icon';
import mapReducer from 'redux/slices/map';
import taskReducer from 'redux/slices/task';
import projectReducer from 'redux/slices/project';
import contractReducer from 'redux/slices/contract';
import contractphotoReducer from 'redux/slices/contractphoto';
import documentcontractReducer from 'redux/slices/documentcontract';
import taskplanningReducer from 'redux/slices/taskplanning';
import documenttaskReducer from 'redux/slices/documenttask';

import rootSaga from 'redux/sagas/rootSaga';

const persistAuthConfig = {
  key: 'auth',
  storage,
  whitelist: ['accessToken']
};

const persistSettingsConfig = {
  key: 'settings',
  storage,
  whitelist: ['themeMode']
};

const iconSettingsConfig = {
  key: 'icons',
  storage,
  whitelist: ['icons']
};

const rootReducer = combineReducers({
  auth: persistReducer<ReturnType<typeof authReducer>>(
    persistAuthConfig,
    authReducer
  ),
  settings: persistReducer<ReturnType<typeof settingsReducer>>(
    persistSettingsConfig,
    settingsReducer
  ),
  icons: persistReducer<ReturnType<typeof iconReducer>>(
    iconSettingsConfig,
    iconReducer
  ),
  geometry: geometryReducer,
  map: mapReducer,
  task: taskReducer,
  project: projectReducer,
  contract: contractReducer,
  contractphoto: contractphotoReducer,
  documentcontract: documentcontractReducer,
  taskplanning: taskplanningReducer,
  documenttask: documenttaskReducer
});

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const configureAppStore = (initialState = {}) => {
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);

  const middleware = [sagaMiddleware];

  const store = configureStore({
    reducer: rootReducer,
    middleware: (gDM) =>
      gDM({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        }
      }).concat([...middleware]),
    preloadedState: initialState,
    devTools: process.env.NODE_ENV !== 'production'
  });

  sagaMiddleware.run(rootSaga);
  return store;
};

const store = configureAppStore();

// types
export type RootState = ReturnType<typeof store.getState>;
export type Dispatch = typeof store.dispatch;
export type Thunk = ThunkAction<
  Promise<unknown>,
  RootState,
  unknown,
  Action<unknown>
>;
export const persistor = persistStore(store, {});

export default store;
