import { takeEvery, ForkEffect, put } from 'redux-saga/effects';

import {
  actions as contractPhotoActions,
  IContractPhoto
} from 'redux/slices/contractphoto';
import { PayloadAction } from '@reduxjs/toolkit';
import * as contractPhotoService from 'services/contractPhotoService';
import apiSaga from 'redux/sagas/apiSaga';

function* _contractPhotoRequest(payloadAction: PayloadAction<any>): any {
  yield* apiSaga(
    contractPhotoService.loadContractPhotos,
    payloadAction.payload,
    function* (data: IContractPhoto[]) {
      yield put(contractPhotoActions.contractPhotoResponse(data));
    },
    function* (error: any) {
      console.log(error);
      yield put(contractPhotoActions.contractPhotoResponse([]));
    }
  );
}

export function* watchContractPhotoSaga(): Generator<ForkEffect, void> {
  yield takeEvery(
    contractPhotoActions.contractPhotoRequest,
    _contractPhotoRequest
  );
}

const ContractPhotoSaga = watchContractPhotoSaga;

export default ContractPhotoSaga;
