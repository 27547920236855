import { takeEvery, ForkEffect, put } from 'redux-saga/effects';

import {
  actions as documenttaskActions,
  IDocumentTask,
  IFileFilter
} from 'redux/slices/documenttask';
import { PayloadAction } from '@reduxjs/toolkit';
import * as documentTaskService from 'services/documentTaskService';
import apiSaga from 'redux/sagas/apiSaga';

import fileDownload from 'js-file-download';

function* _documentTaskRequest(payloadAction: PayloadAction<any>): any {
  yield* apiSaga(
    documentTaskService.loadDocumentsTask,
    payloadAction.payload,
    function* (data: IDocumentTask[]) {
      yield put(documenttaskActions.documentTaskResponse(data));
    },
    function* (error: any) {
      console.log(error);
      yield put(documenttaskActions.documentTaskResponse([]));
    }
  );
}

function* _fileRequest(payloadAction: PayloadAction<IFileFilter>): any {
  const name = payloadAction.payload.name ?? 'anexo.pdf';

  yield* apiSaga(
    documentTaskService.getDocumentTask,
    payloadAction.payload,
    function* (blob: any) {
      fileDownload(blob, name);
      yield put(documenttaskActions.fileResponse());
    },
    function* (error: any) {
      console.log(error);
    }
  );
}

export function* watchDocumentTask(): Generator<ForkEffect, void> {
  yield takeEvery(
    documenttaskActions.documentTaskRequest,
    _documentTaskRequest
  );
  yield takeEvery(documenttaskActions.fileRequest, _fileRequest);
}

const DocumentTaskSaga = watchDocumentTask;

export default DocumentTaskSaga;
