import React from 'react';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import { useCustomSelector } from 'hooks/redux';

interface Props {
  children: React.ReactNode;
}

const MuiThemeProvider: React.FC<Props> = ({ children }) => {
  const { themeMode } = useCustomSelector((state) => state.settings);

  const isLight = themeMode === 'light';

  const theme = createTheme({
    typography: {
      h6: {
        color: '#212121'
      },
      subtitle1: {
        color: '#212121'
      }
    },
    palette: {
      primary: {
        main: '#282c34'
      },
      secondary: {
        main: '#005b95'
      },
      mode: isLight ? 'light' : 'dark'
    }
  });

  return (
    <>
      <StyledEngineProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {children}
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default MuiThemeProvider;
