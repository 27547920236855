import React, { useEffect } from 'react';
import { Divider, Box, Typography } from '@mui/material';
import { useCustomSelector, useCustomDispatch } from 'hooks/redux';

import { actions as actionsTaskPlanning } from 'redux/slices/taskplanning';
import { isEmpty, isUndefined } from 'lodash';
import TextDateContainer from 'components/commons/TextDateContainer';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useTranslation } from 'react-i18next';
import { Translator } from 'components/I18n';

interface ITaskPlanningProps {
  projectId?: number;
  taskId?: number;
}

const TaskPlanning: React.FC<ITaskPlanningProps> = (
  props: ITaskPlanningProps
) => {
  const { t } = useTranslation();
  const { projectId, taskId } = props;
  const { tasksPlanning } = useCustomSelector((state) => state.taskplanning);

  const dispatch = useCustomDispatch();

  useEffect(() => {
    if (projectId !== undefined && taskId !== undefined) {
      dispatch(
        actionsTaskPlanning.taskPlanningRequest({
          id: taskId,
          projectId
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId, taskId]);

  const getTitleStage = (stageId: number): string => {
    if (stageId === 1) {
      return t('labels.task.stage_planning');
    }
    if (stageId === 2) {
      return t('labels.task.stage_bidding');
    }
    if (stageId === 3) {
      return t('labels.task.stage_contract');
    }
    return '';
  };

  return (
    <React.Fragment>
      {!isUndefined(tasksPlanning) && !isEmpty(tasksPlanning) && (
        <React.Fragment>
          <Typography variant="h6">
            <Translator path="labels.task.stages" />
          </Typography>
          <Divider />
          <Box sx={{ height: '5px' }} />
          <TableContainer component={Paper}>
            <Table size="small">
              <TableHead
                sx={{
                  backgroundColor: '#00000030'
                }}
              >
                <TableRow>
                  <TableCell>
                    <Translator path="labels.task.stage" />
                  </TableCell>
                  <TableCell align="right">
                    <Translator path="labels.task.planned" />
                  </TableCell>
                  <TableCell align="right">
                    <Translator path="labels.task.realized" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isUndefined(tasksPlanning) &&
                  tasksPlanning.map((row) => (
                    <TableRow
                      title={getTitleStage(row.stageId)}
                      key={row.dataTypeId}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                        backgroundColor: `#${row.stageColor}`
                      }}
                    >
                      <TableCell>{row.description}</TableCell>
                      <TableCell align="right">
                        <TextDateContainer date={row.planned} />
                      </TableCell>
                      <TableCell align="right">
                        <TextDateContainer date={row.realized} />
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default TaskPlanning;
