import { AxiosRequestConfig } from 'axios';
import { call } from 'redux-saga/effects';

export default function* (
  fn: any,
  parameter: any,
  success: any,
  failure: any
): any {
  try {
    const response: AxiosRequestConfig = yield call(fn, parameter);
    let responseData = {};
    if (response !== undefined) {
      responseData = response.data;
    }
    yield call(success, responseData);
  } catch (error: any) {
    yield call(failure, error);
  }
}
