export default {
  translations: {
    map: {
      title: 'Map of deliverables'
    },
    labels: {
      project: {
        title: 'Project',
        financing_standard: 'Financing Standard',
        borrower: 'Borrower',
        executor: 'Executor',
        signature: 'Signature',
        current: 'Current',
        percentage: 'Time advance'
      },
      contract: {
        title: 'Contracts',
        proveedor: 'Proveedor',
        validy_period: 'Term',
        contract_value: 'Contrat value',
        value_category: 'Amount paid',
        balance_contract: 'Balance to pay',
        situation: 'Situation',
        concluded: {
          inprogress: 'In progress',
          contract_concluded: 'Completed contract',
          paralyzed: 'Paralyzed',
          terminated: 'Terminated'
        },
        pictures: 'Pictures',
        documents: 'Documents'
      },
      task: {
        title: 'Deliverable',
        executor: 'Executor',
        financing_standard: 'Resource source',
        coin: 'Coin',
        local: 'Counterpart',
        justification: 'Justification',
        synthesis: 'Synthesis',
        stage: 'Stage',
        stages: 'Stages',
        date: 'Date',
        planned: 'Planned',
        realized: 'Realized',
        stage_planning: 'Planning stage',
        stage_bidding: 'Bidding stage',
        stage_contract: 'Hiring stage'
      }
    }
  }
};
