import { takeEvery, ForkEffect, put } from 'redux-saga/effects';

import {
  actions as geometryActions,
  IGeometryFilter
} from 'redux/slices/geometry';
import { PayloadAction } from '@reduxjs/toolkit';
import * as geometryService from '../../../services/geometryService';
import apiSaga from 'redux/sagas/apiSaga';

function* _geometriesRequest(
  payloadAction: PayloadAction<IGeometryFilter>
): any {
  yield* apiSaga(
    geometryService.loadGeometries,
    payloadAction.payload,
    function* (data: any) {
      yield put(geometryActions.geometriesResponse(data));
    },
    function* (error: any) {
      console.log(error);
      yield put(geometryActions.geometriesResponse([]));
    }
  );
}

export function* watchGeometrySagas(): Generator<ForkEffect, void> {
  yield takeEvery(geometryActions.geometriesRequest, _geometriesRequest);
}

const geometrySaga = watchGeometrySagas;

export default geometrySaga;
