/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'redux/store';

export interface ILogin {
  email: string;
  password: string;
}

export interface IToken {
  token: string;
}

export interface IAccessToken {
  access_token: string;
  exires_in: number;
  scope: string;
  token_type: string;
}
export interface IAuthState {
  accessToken: IAccessToken;
}

const initialState: IAuthState = {
  accessToken: {} as IAccessToken
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state) => {},
    saveAccessToken: (state, action: PayloadAction<IAccessToken>) => {
      state.accessToken = action.payload;
      return state;
    },
    removeAccessToken: (state) => {
      state.accessToken = initialState.accessToken;
      return state;
    }
  }
});

// Actions
export const actions = authSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const selectIsLogging = (state: RootState) => state.auth.logging

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
export const accessToken = (state: RootState) => state.auth.accessToken;

// Reducer
export default authSlice.reducer;
