/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { rgbToHex, Typography, useTheme } from '@mui/material';
import { useCustomDispatch, useCustomSelector } from 'hooks/redux';
import useMediaQuery from '@mui/material/useMediaQuery';

import { AppbarStyled, BoxStyled } from './HomeStyles';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { isExpired } from 'react-jwt';

import { actions as mapActions } from 'redux/slices/map';
import { actions as authActions } from 'redux/slices/auth';
import { actions as iconActions } from 'redux/slices/icon';
import {
  actions as geometryActions,
  IGeometryFilter
} from 'redux/slices/geometry';

import Mapa from 'components/map/Mapa';

import 'react-sliding-pane/dist/react-sliding-pane.css';
import './Home.css';

import I18n, { Translator } from 'components/I18n';
import Slide from 'components/slide/Slide';
import Searchbox, { IOption } from 'components/searchbox/searchbox';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
type HomeParams = {
  slugCountry: string;
  slugProject: string;
  taskId: string;
};

const Home: React.FC = () => {
  const dispatch = useCustomDispatch();
  const { slugCountry, slugProject, taskId } = useParams<HomeParams>();
  const [geometry, setGeometry] = useState<IOption | undefined>(undefined);

  const { task } = useCustomSelector((state) => state.task);
  const { project } = useCustomSelector((state) => state.project);
  const { accessToken } = useCustomSelector((state) => state.auth);
  const { icons } = useCustomSelector((state) => state.icons);
  const { geometries } = useCustomSelector((state) => state.geometry);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));

  async function generateToken(): Promise<void> {
    const isTokenExpired = isExpired(accessToken.access_token);
    if (isEmpty(accessToken?.access_token) || isTokenExpired) {
      dispatch(authActions.login());
    }
  }

  function loadIcons(): void {
    if (isEmpty(icons)) {
      dispatch(iconActions.iconRequest());
    }
  }

  function loadGeometries(): void {
    const filter: IGeometryFilter = {
      slugCountry: slugCountry ?? null,
      slugProject: slugProject ?? null,
      taskId: taskId ?? null
    };
    dispatch(geometryActions.geometriesRequest(filter));
  }

  function loadData(): void {
    generateToken();
    if (!isEmpty(accessToken?.access_token)) {
      loadIcons();
      loadGeometries();
    }
  }

  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, [accessToken?.access_token, geometry]);

  function onChangeGeometry(id: any): void {
    setGeometry(id);
    if (isEmpty(id)) {
      dispatch(mapActions.hidePanel());
    }
  }

  return (
    <div style={{ overflow: 'hidden' }}>
      <AppbarStyled>
        <img
          src={require('assets/images/site-logo.png')}
          alt="Logo"
          height={36}
        />
        <Typography variant={isXs ? 'subtitle1' : 'h6'}>
          <Translator path="map.title" />
          {' - '}
          {'SAFF'}
        </Typography>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'right'
          }}
        >
          {!isXs && !isEmpty(slugProject) && (
            <span style={{ marginRight: '10px' }}>
              <Searchbox onChangeGeometry={onChangeGeometry} width={600} />
            </span>
          )}
          <I18n />
        </div>
      </AppbarStyled>
      <BoxStyled>
        <Mapa
          geometries={geometries}
          icons={icons}
          geometry={geometry}
          slugProject={slugProject}
        ></Mapa>
        {isXs && !isEmpty(slugProject) && (
          <div style={{ margin: '10px' }}>
            <Searchbox onChangeGeometry={onChangeGeometry} width={370} />
          </div>
        )}
      </BoxStyled>
      <Slide task={task} project={project} />
    </div>
  );
};

export default Home;
