export default {
  translations: {
    map: {
      title: 'Mapa dos entregáveis'
    },
    labels: {
      project: {
        title: 'Projeto',
        financing_standard: 'Financiador',
        borrower: 'Mutuário',
        executor: 'Executor',
        signature: 'Início do contrato',
        current: 'Término do contrato',
        percentage: 'Avanço de tempo'
      },
      contract: {
        title: 'Contratos',
        proveedor: 'Fornecedor',
        validy_period: 'Período de vigência',
        contract_value: 'Valor do contrato',
        value_category: 'Valor pago',
        balance_contract: 'Saldo a pagar',
        situation: 'Situação',
        concluded: {
          inprogress: 'Em andamento',
          contract_concluded: 'Contrato concluído',
          paralyzed: 'Paralisado',
          terminated: 'Rescindido'
        },
        pictures: 'Fotos',
        documents: 'Documentos'
      },
      task: {
        title: 'Entregável',
        executor: 'Executor',
        financing_standard: 'Fonte de recurso',
        coin: 'Moeda',
        local: 'Contrapartida',
        justification: 'Justificativa',
        synthesis: 'Síntese',
        stage: 'Etapa',
        stages: 'Etapas',
        date: 'Data',
        planned: 'Planejada',
        realized: 'Realizada',
        stage_planning: 'Etapa de planejamento',
        stage_bidding: 'Etapa de licitação',
        stage_contract: 'Etapa de contratação'
      }
    }
  }
};
