import { ITaskPlanningFilter } from 'redux/slices/taskplanning/index';
import { takeEvery, ForkEffect, put } from 'redux-saga/effects';

import {
  actions as taskPlanningActions,
  ITaskPlanning
} from 'redux/slices/taskplanning';
import { PayloadAction } from '@reduxjs/toolkit';
import * as taskService from 'services/taskService';
import apiSaga from 'redux/sagas/apiSaga';

function* _taskPlanningRequest(
  payloadAction: PayloadAction<ITaskPlanningFilter>
): any {
  yield* apiSaga(
    taskService.loadTaskPlanning,
    payloadAction.payload,
    function* (data: ITaskPlanning[]) {
      yield put(taskPlanningActions.taskPlanningResponse(data));
    },
    function* (error: any) {
      console.log(error);
      yield put(taskPlanningActions.taskPlanningResponse([]));
    }
  );
}

export function* watchTaskPlanningSagas(): Generator<ForkEffect, void> {
  yield takeEvery(
    taskPlanningActions.taskPlanningRequest,
    _taskPlanningRequest
  );
}

const TaskPlanningSaga = watchTaskPlanningSagas;

export default TaskPlanningSaga;
