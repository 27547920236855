/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDocumentContract {
  id?: number;
  projectId?: number;
  contractId?: number;
  name?: string;
  size?: number;
  extention?: string;
  mimetype?: string;
}

export interface IDocumentContractFilter {
  projectId?: number;
  contractId?: number;
}

export interface IFileFilter {
  id?: number;
  name?: string;
}

export interface IDocumentContractState {
  documents: IDocumentContract[];
  documentContractFilter: IDocumentContractFilter;
  file: IFileFilter;
}

// export const UNINITIALIZED = 'UNINITIALIZED';
// type State = IContract | typeof UNINITIALIZED | null;

const initialState: IDocumentContractState = {
  documents: [],
  documentContractFilter: {} as IDocumentContractFilter,
  file: {} as IFileFilter
};

const documentContractSlice = createSlice({
  name: 'documentsContract',
  initialState,
  reducers: {
    documentContractRequest: (
      state,
      action: PayloadAction<IDocumentContractFilter>
    ) => {
      state.documentContractFilter = action.payload;
      return state;
    },
    documentContractResponse: (
      state,
      action: PayloadAction<IDocumentContract[]>
    ) => {
      state.documents = action.payload;
      return state;
    },
    documentContractClear: (state) => {
      state = initialState;
      return state;
    },
    fileRequest: (state, action: PayloadAction<IFileFilter>) => {
      state.file = action.payload;
    },
    fileResponse: (state) => {
      console.log('nada a fazer');
    }
  }
});

// Actions
export const actions = documentContractSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const teste = (state: RootState): IGeometry[] => state.geometry.geometries;

// Reducer
export default documentContractSlice.reducer;
