/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITask {
  id?: number;
  projectId?: number;
  name?: string;
  financingStandard?: string;
  coin?: string;
  value?: number;
  bancValue?: number;
  localValue?: number | 0;
  executor?: string;
  justification?: string;
  synthesis?: string;
}

export interface ITaskFilter {
  id: number;
  projectId: number;
}

export interface TaskState {
  task: ITask;
  taskFilter: ITaskFilter;
}

const initialState: TaskState = {
  task: {},
  taskFilter: {} as ITaskFilter
};

const taskSlice = createSlice({
  name: 'task',
  initialState,
  reducers: {
    taskRequest: (state, action: PayloadAction<ITaskFilter>) => {
      state.taskFilter = action.payload;
      return state;
    },
    taskResponse: (state, action: PayloadAction<ITask>) => {
      state.task = action.payload;
      return state;
    },
    taskClear: (state) => {
      state = initialState;
      return state;
    }
  }
});

// Actions
export const actions = taskSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const teste = (state: RootState): IGeometry[] => state.geometry.geometries;

// Reducer
export default taskSlice.reducer;
