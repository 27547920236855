import { ITask } from './../redux/slices/geometry/index';
import { ITaskFilter } from './../redux/slices/task/index';
import axios from 'utils/axios';

export async function loadTask(taskFilter: ITaskFilter): Promise<ITask> {
  const retorno: ITask = await axios.get(
    `/task/${taskFilter.projectId}/${taskFilter.id}`
  );
  return retorno;
}

export async function loadTaskPlanning(
  taskFilter: ITaskFilter
): Promise<ITask> {
  const retorno: ITask = await axios.get(
    `/task/${taskFilter.projectId}/${taskFilter.id}/planning`
  );
  return retorno;
}
