import { ITaskFilter } from 'redux/slices/task/index';
import { takeEvery, ForkEffect, put } from 'redux-saga/effects';

import { actions as taskActions, ITask } from 'redux/slices/task';
import { actions as projectActions } from 'redux/slices/project';
import { actions as mapActions } from 'redux/slices/map';
import { actions as contractActions } from 'redux/slices/contract';
import { PayloadAction } from '@reduxjs/toolkit';
import * as taskService from 'services/taskService';
import apiSaga from 'redux/sagas/apiSaga';

function* _taskRequest(payloadAction: PayloadAction<ITaskFilter>): any {
  const { projectId, id } = payloadAction.payload;

  yield* apiSaga(
    taskService.loadTask,
    payloadAction.payload,
    function* (data: ITask) {
      yield put(
        projectActions.projectRequest({
          id: projectId
        })
      );
      yield put(
        contractActions.contractTaskRequest({
          taskId: id,
          projectId
        })
      );
      yield put(taskActions.taskResponse(data));
      yield put(mapActions.showPanel());
    },
    function* (error: any) {
      console.log(error);
      yield put(taskActions.taskResponse({}));
    }
  );
}

export function* watchTaskSagas(): Generator<ForkEffect, void> {
  yield takeEvery(taskActions.taskRequest, _taskRequest);
}

const TaskSaga = watchTaskSagas;

export default TaskSaga;
