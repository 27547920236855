import { takeEvery, ForkEffect, put } from 'redux-saga/effects';

import {
  actions as documentcontractActions,
  IDocumentContract,
  IFileFilter
} from 'redux/slices/documentcontract';
import { PayloadAction } from '@reduxjs/toolkit';
import * as documentContractService from 'services/documentContractService';
import apiSaga from 'redux/sagas/apiSaga';

import fileDownload from 'js-file-download';

function* _documentContractRequest(payloadAction: PayloadAction<any>): any {
  yield* apiSaga(
    documentContractService.loadDocumentsContract,
    payloadAction.payload,
    function* (data: IDocumentContract[]) {
      yield put(documentcontractActions.documentContractResponse(data));
    },
    function* (error: any) {
      console.log(error);
      yield put(documentcontractActions.documentContractResponse([]));
    }
  );
}

function* _fileRequest(payloadAction: PayloadAction<IFileFilter>): any {
  const name = payloadAction.payload.name ?? 'anexo.pdf';

  yield* apiSaga(
    documentContractService.getDocumentContract,
    payloadAction.payload,
    function* (blob: any) {
      fileDownload(blob, name);
      yield put(documentcontractActions.fileResponse());
    },
    function* (error: any) {
      console.log(error);
    }
  );
}

export function* watchDocumentContract(): Generator<ForkEffect, void> {
  yield takeEvery(
    documentcontractActions.documentContractRequest,
    _documentContractRequest
  );
  yield takeEvery(documentcontractActions.fileRequest, _fileRequest);
}

const DocumentContractSaga = watchDocumentContract;

export default DocumentContractSaga;
