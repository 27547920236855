export default {
  translations: {
    map: {
      title: 'Mapa de entregables'
    },
    labels: {
      project: {
        title: 'Proyecto',
        financing_standard: 'Financiero',
        borrower: 'Prestatario',
        executor: 'Ejecutor',
        signature: 'Comienzo del contrato',
        current: 'Fin del contrato',
        percentage: 'Avance de tiempo'
      },
      contract: {
        title: 'Contratos',
        proveedor: 'Proveedor',
        validy_period: 'Término',
        contract_value: 'Valor del contrato',
        value_category: 'Valor pago',
        balance_contract: 'Saldo a pagar',
        situation: 'Situación',
        concluded: {
          inprogress: 'En proceso',
          contract_concluded: 'Contrato completado',
          paralyzed: 'Paralizado',
          terminated: 'Terminado'
        },
        pictures: 'Fotos',
        documents: 'Documentos'
      },
      task: {
        title: 'Entregable',
        executor: 'Ejecutor',
        financing_standard: 'Fuente de recurso',
        coin: 'Moneda',
        local: 'Contrapartida',
        justification: 'Justificación',
        synthesis: 'Síntesis',
        stage: 'Etapa',
        stages: 'Etapas',
        date: 'Fecha',
        planned: 'Planeada',
        realized: 'Realizada',
        stage_planning: 'Etapa de planificación',
        stage_bidding: 'Etapa de licitación',
        stage_contract: 'Etapa de contratación'
      }
    }
  }
};
