import axios from 'utils/axios';
import { IAccessToken } from 'redux/slices/auth/index';

export async function getAccessToken(): Promise<IAccessToken> {
  const body: any = {
    grant_type: 'client_credentials',
    scope: 'any'
  };

  let auth = '';
  if (process.env.REACT_APP_CLIENT_AUTHORIZATION !== undefined) {
    auth = process.env.REACT_APP_CLIENT_AUTHORIZATION;
  }

  const headers: any = {
    headers: {
      Authorization: 'Basic ' + auth,
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  };

  const retorno: IAccessToken = await axios.post('/oauth/token', body, headers);
  return retorno;
}
