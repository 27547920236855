import { takeEvery, ForkEffect, put } from 'redux-saga/effects';

import { actions as iconActions } from 'redux/slices/icon';
import { PayloadAction } from '@reduxjs/toolkit';
import * as iconService from '../../../services/iconService';
import apiSaga from 'redux/sagas/apiSaga';

function* _iconRequest(payloadAction: PayloadAction<any>): any {
  yield* apiSaga(
    iconService.loadIcons,
    payloadAction.payload,
    function* (data: any) {
      yield put(iconActions.iconResponse(data));
    },
    function* (error: any) {
      console.log(error);
      yield put(iconActions.iconResponse([]));
    }
  );
}

export function* watchIconSagas(): Generator<ForkEffect, void> {
  yield takeEvery(iconActions.iconRequest, _iconRequest);
}

const IconSaga = watchIconSagas;

export default IconSaga;
