import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

// import { br, es, us } from 'assets/flag-icons';

import Flag from './Flag';
import './I18n.css';

const I18n: React.FC = () => {
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState('');

  useEffect(() => {
    const selectedLanguage = i18n.language;
    setLanguage(selectedLanguage);
  }, [i18n.language]);

  function handleChangeLanguage(language: string): void {
    i18n.changeLanguage(language);
  }

  return (
    <div className="flags-container">
      <Select
        value={language}
        style={{
          backgroundColor: '#fff'
        }}
        className="flag-select"
        onChange={(event: SelectChangeEvent) => {
          setLanguage(event.target.value);
          handleChangeLanguage(event.target.value);
        }}
      >
        <MenuItem value={'pt-BR'}>
          <Flag image={require('assets/flag-icons/br.png')}></Flag>
        </MenuItem>
        <MenuItem value={'en-US'}>
          <Flag image={require('assets/flag-icons/us.png')}></Flag>
        </MenuItem>
        <MenuItem value={'es-ES'}>
          <Flag image={require('assets/flag-icons/es.png')}></Flag>
        </MenuItem>
      </Select>
    </div>
  );
};

export default I18n;
