import { experimentalStyled as styled } from '@mui/material/styles';
import {
  AppBar,
  Container,
  Box,
  Paper,
  Card,
  LinearProgress
} from '@mui/material';

const AppbarHeight = 64;

export const AppbarStyled = styled(AppBar)({
  height: AppbarHeight,
  paddingLeft: 20,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  backgroundColor: '#f2f2f7'
});

export const AppbarContainerStyled = styled(Container)({
  height: '100%',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'start'
});

export const BodyContainerStyled = styled(Container)({
  paddingTop: 24 + AppbarHeight,
  width: '100%',
  height: '100vh'
});

export const CardStyled = styled(Card)(({ theme }) => ({
  padding: '6px 8px',
  borderRadius: '0'
}));

export const BoxStyled = styled(Box)(({ theme }) => ({
  paddingTop: AppbarHeight,
  height: '100vh',
  width: '100%'
}));

export const ItemStyled = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary
}));

export const LinearProgressStyled = styled(LinearProgress)(({ theme }) => ({
  height: '15px', // A altura da barra
  borderRadius: '0px', // Borda arredondada
  backgroundColor: '#6ddde7', // Cor do fundo
  '& .MuiLinearProgress-bar': {
    backgroundColor: '#00828e' // Cor da barra
  }
}));
