/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITaskPlanning {
  dataTypeId: number;
  projectId: number;
  description: string;
  orderNumber: number;
  planned: string;
  realized: string;
  stageColor: string;
  stageId: number;
}

export interface ITaskPlanningFilter {
  id: number;
  projectId: number;
}

export interface TaskPlanningState {
  tasksPlanning: ITaskPlanning[];
  taskPlanningFilter: ITaskPlanningFilter;
}

const initialState: TaskPlanningState = {
  tasksPlanning: [],
  taskPlanningFilter: {} as ITaskPlanningFilter
};

const taskPlanningSlice = createSlice({
  name: 'taskPlanning',
  initialState,
  reducers: {
    taskPlanningRequest: (
      state,
      action: PayloadAction<ITaskPlanningFilter>
    ) => {
      state.taskPlanningFilter = action.payload;
      return state;
    },
    taskPlanningResponse: (state, action: PayloadAction<ITaskPlanning[]>) => {
      state.tasksPlanning = action.payload;
      return state;
    }
  }
});

// Actions
export const actions = taskPlanningSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const teste = (state: RootState): IGeometry[] => state.geometry.geometries;

// Reducer
export default taskPlanningSlice.reducer;
