/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import { Box, useTheme } from '@mui/material';
import { useCustomDispatch, useCustomSelector } from 'hooks/redux';
import SlidingPane from 'react-sliding-pane';
import useMediaQuery from '@mui/material/useMediaQuery';

import Task from 'components/task/Task';
import Project from 'components/project/Project';
import ContractList from 'components/contract/ContractList';

import { actions as mapActions } from 'redux/slices/map';
import { ITask, actions as taskActions } from 'redux/slices/task';
import { IProject, actions as projectActions } from 'redux/slices/project';
import { actions as contractActions } from 'redux/slices/contract';
import { actions as contractPhotoActions } from 'redux/slices/contractphoto';

import './Slide.css';

interface ISlideProps {
  task: ITask;
  project: IProject;
}

const Slide: React.FC<ISlideProps> = (props: ISlideProps) => {
  const { task, project } = props;
  const { showPanel } = useCustomSelector((state) => state.map);

  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('sm'));
  let widthSliding = 574;
  if (isXs) {
    widthSliding = 370;
  }

  const dispatch = useCustomDispatch();

  function clearState(): void {
    dispatch(taskActions.taskClear());
    dispatch(projectActions.projectClear());
    dispatch(contractActions.contractClear());
    dispatch(contractPhotoActions.contractPhotoClear());
  }

  function onClosePanel(): void {
    dispatch(mapActions.hidePanel());
  }

  function onAfterOpen(): void {}

  function onAfterClose(): void {
    clearState();
  }

  return (
    <SlidingPane
      onAfterClose={onAfterClose}
      isOpen={showPanel}
      title={task.name}
      subtitle={project.name}
      from="left"
      width={`${widthSliding}px`}
      onRequestClose={onClosePanel}
      onAfterOpen={onAfterOpen}
      className=""
    >
      <Project />
      <Box height={10} />
      <Task />
      <Box height={10} />
      <ContractList />
    </SlidingPane>
  );
};

export default Slide;
