import React from 'react';
import { useTranslation } from 'react-i18next';

interface ITranslator {
  path: string;
}

const Translator: React.FC<ITranslator> = (props: ITranslator) => {
  const { t } = useTranslation();
  // eslint-disable-next-line prettier/prettier
  return (
    <>{t(props.path)}</>
  );
};

export default Translator;
