import React from 'react';
import { Divider, List, ListItem, ListItemText } from '@mui/material';
import { Translator } from 'components/I18n';
import TextNumberContainer from 'components/commons/TextNumberContainer';
import TextDateContainer from 'components/commons/TextDateContainer';
import { IContract } from 'redux/slices/contract';

interface IContractProps {
  contract: IContract;
}

const Contract: React.FC<IContractProps> = (props: IContractProps) => {
  const { contract } = props;
  return (
    <>
      <List component="nav" dense disablePadding>
        <Divider />
        <ListItem dense disablePadding>
          <ListItemText primary="Objeto" secondary={contract.description} />
        </ListItem>
        <Divider />
        <ListItem dense disablePadding>
          <ListItemText
            primary=<Translator path="labels.contract.proveedor" />
            secondary={contract.proveedor}
          />
        </ListItem>
        <ListItem dense disablePadding>
          <ListItemText
            primary=<Translator path="labels.contract.validy_period" />
            secondary={
              <div>
                <TextDateContainer date={contract.signature} />
                {' - '}
                <TextDateContainer date={contract.end} />
              </div>
            }
          />
          {/*
          <ListItemText
            secondary=<Chip
              label=<Translator
                path={`labels.${contract.situation ?? ''}` ?? ''}
              />
              sx={{
                backgroundColor: `${
                  contract.situation === 'contract.concluded.inprogress'
                    ? '#FFB70F'
                    : '#00d014'
                }`
              }}
              color="warning"
            />
            /> */}
        </ListItem>
        <ListItem dense disablePadding>
          <ListItemText
            primary={
              <div>
                <Translator path="labels.contract.contract_value" />
                {' - '}
                {contract.coin}
              </div>
            }
            secondary=<TextNumberContainer
              value={contract.valueContract ?? 0}
            />
          />
        </ListItem>

        <ListItem dense disablePadding>
          <ListItemText
            primary={
              <div>
                <Translator path="labels.contract.value_category" />
              </div>
            }
            secondary=<TextNumberContainer
              value={contract.valueCategory ?? 0}
            />
          />
          <ListItemText
            primary={
              <div>
                <Translator path="labels.contract.balance_contract" />
              </div>
            }
            secondary=<TextNumberContainer
              value={contract.balanceContract ?? 0}
            />
          />
        </ListItem>
      </List>
    </>
  );
};

export default Contract;
