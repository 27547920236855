/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IProject {
  id?: number;
  name?: string;
  type?: string;
  borrower?: string;
  executor?: string;
  financingStandard?: string;
  signature: string;
  current: string;
  percentage?: number | 0;
}

export interface IProjecFilter {
  id: number;
}

export interface ProjectState {
  project: IProject;
  projectFilter: IProjecFilter;
}

const initialState: ProjectState = {
  project: {} as IProject,
  projectFilter: {} as IProjecFilter
};

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {
    projectRequest: (state, action: PayloadAction<IProjecFilter>) => {
      state.projectFilter = action.payload;
      return state;
    },
    projectResponse: (state, action: PayloadAction<IProject>) => {
      state.project = action.payload;
      return state;
    },
    projectClear: (state) => {
      state = initialState;
      return state;
    }
  }
});

// Actions
export const actions = projectSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const teste = (state: RootState): IGeometry[] => state.geometry.geometries;

// Reducer
export default projectSlice.reducer;
