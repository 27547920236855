import React from 'react';

interface IFlag {
  image: string;
}

const Flag: React.FC<IFlag> = (props: IFlag) => (
  <img alt="flag" src={props.image} height="16" />
);

export default Flag;
