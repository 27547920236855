import React from 'react';
import { Chip } from '@mui/material';
import { Translator } from 'components/I18n';

interface IChipStatus {
  situation: string | undefined;
}

const ChipStatus: React.FC<IChipStatus> = (props: IChipStatus) => {
  let color: string = '#000000';
  if (props.situation === 'contract.concluded.inprogress') {
    color = '#FFB70F';
  }
  if (props.situation === 'contract.concluded.contract_concluded') {
    color = '#00d014';
  }
  if (props.situation === 'contract.concluded.paralyzed') {
    color = '#ff0000';
  }
  return (
    <>
      <Chip
        size="small"
        label=<Translator path={`labels.${props.situation ?? ''}` ?? ''} />
        sx={{
          width: '150px',
          backgroundColor: `${color}`
        }}
        color="warning"
      />
    </>
  );
};

export default ChipStatus;
