// import { IProject } from './../../slices/project/index';
import { takeEvery, ForkEffect, put } from 'redux-saga/effects';

import { actions as projectActions, IProject } from 'redux/slices/project';
import { PayloadAction } from '@reduxjs/toolkit';
import * as projectService from '../../../services/projectService';
import apiSaga from 'redux/sagas/apiSaga';

function* _projectRequest(payloadAction: PayloadAction<any>): any {
  yield* apiSaga(
    projectService.loadProject,
    payloadAction.payload,
    function* (data: IProject) {
      yield put(projectActions.projectResponse(data));
    },
    function* (error: any) {
      console.log(error);
      yield put(
        projectActions.projectResponse({
          current: '',
          signature: ''
        })
      );
    }
  );
}

export function* watchProjectSagas(): Generator<ForkEffect, void> {
  yield takeEvery(projectActions.projectRequest, _projectRequest);
}

const ProjectSaga = watchProjectSagas;

export default ProjectSaga;
