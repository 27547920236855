/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useCustomSelector, useCustomDispatch } from 'hooks/redux';

import { IContract } from 'redux/slices/contract';
import {
  IContractPhoto,
  actions as actionsContractPhoto
} from 'redux/slices/contractphoto';

import 'photoswipe/dist/photoswipe.css';

import { Gallery, Item } from 'react-photoswipe-gallery';
import { Divider, Typography } from '@mui/material';
import { Translator } from 'components/I18n';
import { isEmpty } from 'lodash';

interface IContractProps {
  contract: IContract;
}

const ContractPhoto: React.FC<IContractProps> = (props: IContractProps) => {
  const { contract } = props;
  const { contractPhotos } = useCustomSelector((state) => state.contractphoto);

  const filterPhotos: IContractPhoto[] = contractPhotos.filter(
    (fa) => fa.projectId === contract.projectId && fa.contractId === contract.id
  );

  const dispatch = useCustomDispatch();

  useEffect(() => {
    dispatch(
      actionsContractPhoto.contractPhotoRequest({
        contractId: contract.id,
        projectId: contract.projectId
      })
    );
  }, []);

  const loadThumbnail = (f: IContractPhoto): any => {
    const url = `${process.env.REACT_APP_URL_API}/contractphoto/${f.id}`;
    const urlthumbnail = `${process.env.REACT_APP_URL_API}/contractphoto/${f.id}/thumbnail`;
    return (
      <Item
        original={url}
        thumbnail={urlthumbnail}
        key={f.id}
        width={f.width}
        height={f.height}
        caption={f.name}
        originalSrcset={url}
      >
        {({ ref, open }) => (
          <img
            ref={ref as React.RefObject<HTMLImageElement>}
            onClick={open}
            src={urlthumbnail}
            width={100}
            height={100}
            alt={f.name}
            style={{ margin: 2, cursor: 'pointer', borderRadius: 10 }}
          />
        )}
      </Item>
    );
  };

  return (
    <>
      {!isEmpty(filterPhotos) && (
        <>
          <Typography variant="h6">
            <Translator path="labels.contract.pictures" />
          </Typography>
          <Divider />
          <Gallery>{filterPhotos.map((f) => loadThumbnail(f))}</Gallery>
        </>
      )}
    </>
  );
};

export default ContractPhoto;
