/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IContractPhoto {
  id?: number;
  projectId?: number;
  contractId?: number;
  name?: string;
  size?: number;
  extention?: string;
  mimetype?: string;
  height?: number;
  width?: number;
}

export interface IContractPhotoFilter {
  projectId?: number;
  contractId?: number;
}

export interface ContractPhotoState {
  contractPhotos: IContractPhoto[];
  contractPhotoFilter: IContractPhotoFilter;
}

// export const UNINITIALIZED = 'UNINITIALIZED';
// type State = IContract | typeof UNINITIALIZED | null;

const initialState: ContractPhotoState = {
  contractPhotos: [],
  contractPhotoFilter: {} as IContractPhotoFilter
};

const contractPhotoSlice = createSlice({
  name: 'contractphoto',
  initialState,
  reducers: {
    contractPhotoRequest: (
      state,
      action: PayloadAction<IContractPhotoFilter>
    ) => {
      state.contractPhotoFilter = action.payload;
      return state;
    },
    contractPhotoResponse: (state, action: PayloadAction<IContractPhoto[]>) => {
      state.contractPhotos = action.payload;
      return state;
    },
    contractPhotoClear: (state) => {
      state = initialState;
      return state;
    }
  }
});

// Actions
export const actions = contractPhotoSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const teste = (state: RootState): IGeometry[] => state.geometry.geometries;

// Reducer
export default contractPhotoSlice.reducer;
