/* eslint-disable @typescript-eslint/restrict-template-expressions */
import React from 'react';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  CardContent,
  CardHeader,
  Typography
} from '@mui/material';
import { useCustomSelector } from 'hooks/redux';
import { Translator } from 'components/I18n';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import Contract from 'components/contract/Contract';
import ContractPhoto from 'components/contractphoto/ContractPhoto';
import DocumentContract from 'components/documentcontract/DocumentContract';
import { CardStyled } from 'pages/Home/HomeStyles';
import ChipStatus from './ChipStatus';

const ContractList: React.FC = () => {
  const { contracts } = useCustomSelector((state) => state.contract);
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      {contracts.length > 0 && (
        <CardStyled raised>
          <CardContent>
            <CardHeader
              title={<Translator path="labels.contract.title" />}
              style={{ textAlign: 'center' }}
            />
            <div>
              {contracts.map((c) => {
                return (
                  <Accordion
                    key={c.id}
                    expanded={expanded === `panel${c.id}`}
                    onChange={handleChange(`panel${c.id}`)}
                    sx={{
                      border: '1px solid #bcbcbc',
                      marginBottom: 1
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls={`panel${c.id}a--content`}
                      id={`panel${c.id}a-header`}
                    >
                      <div
                        style={{
                          width: '100%'
                        }}
                      >
                        <div
                          style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-between'
                          }}
                        >
                          <Typography variant="h6">
                            {c.contractNumber}
                          </Typography>
                          <ChipStatus situation={c.situation} />
                        </div>
                        <div>
                          <Typography variant="caption">
                            <LinesEllipsis
                              text={c.description}
                              maxLine="2"
                              ellipsis="..."
                              trimRight
                              basedOn="letters"
                            />
                          </Typography>
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Contract contract={c} key={c.id} />
                      <ContractPhoto contract={c} />
                      <DocumentContract contract={c} />
                    </AccordionDetails>
                  </Accordion>
                );
              })}
            </div>
          </CardContent>
        </CardStyled>
      )}
    </>
  );
};

export default ContractList;
