import axios from 'utils/axios';
import _ from 'lodash';

import { IGeometry, IGeometryFilter } from './../redux/slices/geometry/index';

export async function loadGeometries(
  filter: IGeometryFilter
): Promise<IGeometry[]> {
  let params = '';
  if (!_.isNull(filter.slugCountry)) {
    params += `/${filter.slugCountry}`;
  }
  if (!_.isNull(filter.slugProject)) {
    params += `/${filter.slugProject}`;
  }
  if (!_.isNull(filter.taskId)) {
    params += `/${filter.taskId}`;
  }
  const retorno: IGeometry[] = await axios.get('/map' + params);
  return retorno;
}
