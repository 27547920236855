/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useCustomSelector, useCustomDispatch } from 'hooks/redux';
import FileDownload from '@mui/icons-material/FileDownload';
import AttachFileIcon from '@mui/icons-material/AttachFile';

import { IContract } from 'redux/slices/contract';
import {
  IDocumentContract,
  actions as actionsDocumentContract
} from 'redux/slices/documentcontract';

import 'photoswipe/dist/photoswipe.css';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography
} from '@mui/material';
import { Translator } from 'components/I18n';
import { isEmpty, toNumber } from 'lodash';
import TextNumberContainer from 'components/commons/TextNumberContainer';

interface IDocumentContractProps {
  contract: IContract;
}

const DocumentContract: React.FC<IDocumentContractProps> = (
  props: IDocumentContractProps
) => {
  const { contract } = props;
  const { documents } = useCustomSelector((state) => state.documentcontract);

  const filterDocuments: IDocumentContract[] = documents.filter(
    (fa) => fa.projectId === contract.projectId && fa.contractId === contract.id
  );

  const dispatch = useCustomDispatch();

  useEffect(() => {
    dispatch(
      actionsDocumentContract.documentContractRequest({
        projectId: contract.projectId,
        contractId: contract.id
      })
    );
  }, []);

  function handleClick(d: IDocumentContract): any {
    dispatch(
      actionsDocumentContract.fileRequest({
        id: d.id,
        name: d.name
      })
    );
  }

  return (
    <>
      {!isEmpty(filterDocuments) && (
        <>
          <Typography variant="h6">
            <Translator path="labels.contract.documents" />
          </Typography>
          <Divider />
          <List dense={true}>
            {filterDocuments.map((d) => (
              <ListItem
                key={d.id}
                secondaryAction={
                  <IconButton
                    edge="end"
                    aria-label="dowload"
                    onClick={() => handleClick(d)}
                  >
                    <FileDownload />
                  </IconButton>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <AttachFileIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={d.name}
                  secondary={
                    <div>
                      <TextNumberContainer value={toNumber(d.size) / 1024} />{' '}
                      bytes
                    </div>
                  }
                />
              </ListItem>
            ))}
          </List>
        </>
      )}
    </>
  );
};

export default DocumentContract;
