import React from 'react';
import {
  CardContent,
  CardHeader,
  Divider,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { Translator } from 'components/I18n';
import { useCustomSelector } from 'hooks/redux';

import TextNumberContainer from 'components/commons/TextNumberContainer';
import { CardStyled } from 'pages/Home/HomeStyles';
import { isEmpty } from 'lodash';
import TaskPlanning from './TaskPlanning';
import DocumentTask from 'components/documenttask/DocumentTask';

const Task: React.FC = () => {
  const { task } = useCustomSelector((state) => state.task);
  return (
    <>
      <CardStyled raised>
        <CardContent>
          <CardHeader
            title=<Translator path="labels.task.title" />
            style={{ textAlign: 'center' }}
          />
          <List component="nav" dense disablePadding>
            <ListItem dense disablePadding>
              <ListItemText
                primary={task.name}
                className="primary"
                primaryTypographyProps={{
                  fontWeight: '700'
                }}
              />
            </ListItem>
            <Divider />
            <ListItem dense disablePadding>
              <ListItemText
                primary=<Translator path="labels.task.executor" />
                secondary={task.executor}
              />
            </ListItem>
            <Divider />
            <ListItem dense disablePadding>
              <ListItemText
                primary={
                  <div>
                    <Translator path="labels.task.financing_standard" />
                    {' - '}
                    {task.coin}
                  </div>
                }
              />
            </ListItem>
            <ListItem dense disablePadding>
              <ListItemText
                primary={task.financingStandard}
                secondary=<TextNumberContainer value={task.bancValue ?? 0} />
              />
              <ListItemText
                primary=<Translator path="labels.task.local" />
                secondary=<TextNumberContainer value={task.localValue ?? 0} />
              />
            </ListItem>
            {!isEmpty(task.justification) && (
              <React.Fragment>
                <Divider />
                <ListItem dense disablePadding>
                  <ListItemText
                    primary=<Translator path="labels.task.justification" />
                    secondary={task.justification}
                  />
                </ListItem>
              </React.Fragment>
            )}
            {!isEmpty(task.justification) && (
              <React.Fragment>
                <Divider />
                <ListItem dense disablePadding>
                  <ListItemText
                    primary=<Translator path="labels.task.synthesis" />
                    secondary={task.synthesis}
                  />
                </ListItem>
              </React.Fragment>
            )}
          </List>

          <TaskPlanning projectId={task.projectId} taskId={task.id} />
          <DocumentTask projectId={task.projectId} taskId={task.id} />
        </CardContent>
      </CardStyled>
    </>
  );
};

export default Task;
