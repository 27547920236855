import { all, fork, AllEffect, ForkEffect } from 'redux-saga/effects';
import authSaga from 'redux/sagas/auth';
import geometrySaga from 'redux/sagas/geometry';
import iconSaga from 'redux/sagas/icon';
import taskSaga from 'redux/sagas/task';
import projectSaga from 'redux/sagas/project';
import contractSaga from 'redux/sagas/contract';
import contractPhotoSaga from 'redux/sagas/contractphoto';
import documentContractSaga from 'redux/sagas/documentcontract';
import taskplanningSaga from 'redux/sagas/taskplanning';
import documentTaskSaga from 'redux/sagas/documenttask';

export default function* rootSaga(): Generator<
  AllEffect<ForkEffect<void>>,
  void,
  unknown
> {
  yield all([
    fork(authSaga),
    fork(geometrySaga),
    fork(iconSaga),
    fork(projectSaga),
    fork(taskSaga),
    fork(contractSaga),
    fork(contractPhotoSaga),
    fork(documentContractSaga),
    fork(taskplanningSaga),
    fork(documentTaskSaga)
  ]);
}
