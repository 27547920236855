/* eslint-disable @typescript-eslint/consistent-type-assertions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IContract {
  id?: number;
  projectId?: number;
  contractNumber?: string;
  description?: string;
  proveedor?: string;
  coin?: string;
  signature?: string;
  /* start?: string; */
  end?: string;
  situation?: string;
  valueContract?: number;
  valueActual?: number;
  valueCategory?: number;
  balanceContract?: number;
  taskId?: number;
}

export interface IContractFilter {
  projectId: number;
  id: number;
}

export interface IContractTaskFilter {
  projectId: number;
  taskId: number;
}

export interface ContractState {
  contracts: IContract[];
  contract: IContract;
  contractTaskFilter: IContractTaskFilter;
  contractFilter: IContractFilter;
}

// export const UNINITIALIZED = 'UNINITIALIZED';
// type State = IContract | typeof UNINITIALIZED | null;

const initialState: ContractState = {
  contracts: [],
  contract: {},
  contractTaskFilter: {} as IContractTaskFilter,
  contractFilter: {} as IContractFilter
};

const contractSlice = createSlice({
  name: 'contract',
  initialState,
  reducers: {
    contractTaskRequest: (
      state,
      action: PayloadAction<IContractTaskFilter>
    ) => {
      state.contractTaskFilter = action.payload;
      return state;
    },
    contractTaskResponse: (state, action: PayloadAction<IContract[]>) => {
      state.contracts = action.payload;
      return state;
    },
    contractRequest: (state, action: PayloadAction<IContractFilter>) => {
      state.contractFilter = action.payload;
      return state;
    },
    contractResponse: (state, action: PayloadAction<IContract>) => {
      state.contract = action.payload;
      return state;
    },
    contractClear: (state, action: PayloadAction) => {
      state = initialState;
      return state;
    }
  }
});

// Actions
export const actions = contractSlice.actions;

// Selectors
// export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
// export const teste = (state: RootState): IGeometry[] => state.geometry.geometries;

// Reducer
export default contractSlice.reducer;
